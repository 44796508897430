import React, { useEffect, useRef } from "react";
import { ReactComponent as Icon } from "../../images/icon-black.svg";
import { ReactComponent as AiIcon } from "../../images/ai-icon.svg";
import ReactMarkdown from "react-markdown";
import fakeMessageData from "../../api/fakeMessageData";
import "./index.scss";
const MessageList = ({ isChatLoading, messages, chatError, useFakeData }) => {
  const messageListRef = useRef(null);
  const messageData = useFakeData ? fakeMessageData : messages;

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  };

  const CustomLink = ({ href, children }) => {
    const handleNavigation = (event) => {
      event.preventDefault();
      window.open(href, "_blank", "noopener noreferrer");
    };

    return (
      <a href={href} onPointerUp={handleNavigation} className="shuts-chat-link">
        {children}
      </a>
    );
  };

  return (
    <div className="shuts-chat-msg-list" ref={messageListRef}>
      <h1
        className={`shuts-chat-title ${
          Object.keys(messageData).length > 0 && "shuts-chat-title--hide"
        }`}
      >
        <Icon />
        Need Help? I'm Here to Assist!
      </h1>
      {messageData && Object.keys(messageData).length > 0 && (
        <>
          {Object.values(messageData).map((message, index) => (
            <div
              key={index}
              className={`shuts-chat-msg-row ${
                message.role === 1
                  ? "shuts-chat-msg-row--user"
                  : "shuts-chat-msg-row--bot"
              } ${index === 0 && "shuts-chat-msg-row--first"}`}
            >
              {message.role !== 1 && <AiIcon />}
              <div
                className={`shuts-chat-msg ${
                  message.role === 1
                    ? "shuts-chat-msg--user"
                    : "shuts-chat-msg--bot"
                } ${index === 0 && "shuts-chat-msg--first"}`}
              >
                <ReactMarkdown
                  components={{
                    a: CustomLink,
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              </div>
            </div>
          ))}
        </>
      )}
      {isChatLoading && (
        <div className="shuts-chat-msg-row--bot">
          <AiIcon />
          <p className="shuts-chat-msg shuts-chat-msg--bot">
            <span className="c-three-dots-loader"></span>
          </p>
        </div>
      )}
      <h1
        className={`shuts-chat-title shuts-chat-title--error ${
          !chatError && "shuts-chat-title--hide shuts-chat-title--hide-error"
        }`}
      >
        <Icon />
        Oops! Something went wrong. Please try again.
      </h1>
    </div>
  );
};

export default MessageList;
